import React from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

export function wrapPageElement ({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment
  return <Layout {...props}>{element}</Layout>
}

export const onClientEntry = () => {
  datadogLogs.init({
    clientToken: 'pubb440e5b6ae3335c520a4c3ab91781f47',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100
  })
  datadogRum.init({
    applicationId: 'ef211d0c-14a9-48e5-9613-b47edfc89f6d',
    clientToken: 'pub82994e2dc3b07d9d5a372b47cb17a4b4',
    site: 'datadoghq.com',
    service: 'bwnuclearconstellation',
    env: 'prod',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  })
}
